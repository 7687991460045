@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: local("OpenSans-SemiBold"),
    url(./fonts/OpenSans-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"), url(./fonts/OpenSans-Bold.ttf) format("truetype");
}
.body-text {
  font-family: "OpenSans" !important;
  font-size: 16px !important;
}
.body-header {
  font-family: "OpenSans-SemiBold" !important;
  font-size: 16px !important;
}
.title {
  font-family: "OpenSans" !important;
  font-size: 20px !important;
}
.subTitle {
  font-family: "OpenSans-SemiBold" !important;
  font-size: 14px !important;
}
.caption {
  font-family: "OpenSans" !important;
  font-size: 12px !important;
}
.openSans {
  font-family: "OpenSans" !important;
}
.button-text {
  font-weight: 600px !important;
  font-size: 16px !important;
}

.text-hidden {
  overflow: hidden;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
}
